import "./home.css";
import React from "react";
import hero from "../../assets/image/hero.jpg";
import logo from "../../assets/image/logo.svg";
import { useEffect, useState } from "react";
import "react-gallery-carousel/dist/index.css";
import separator from "../../assets/image/separator.svg";
import impasto from "../../assets/image/impasto.jpg";
import innovalis from "../../assets/image/innovalis.svg";
import birra from "../../assets/image/birra.jpg";
import pizza from "../../assets/image/pizza.jpg";
import aperitivo from "../../assets/image/aperitivo.jpg";
import tagliere from "../../assets/image/tagliere.jpg";
import facebook from "../../assets/icons/facebook.svg";
import instagram from "../../assets/icons/instagram.svg";
import { useFormFields, useMailChimpForm } from "use-mailchimp-form";

const Home = () => {
	const url = "https://ilbalconcinofoodbeer.us21.list-manage.com/subscribe/post?u=0dc1a5b547346e0502fafa761&amp;id=53441350c2&amp;f_id=00b8f4e6f0";
	const { loading, error, success, message, handleSubmit } = useMailChimpForm(url);
	const { fields, handleFieldChange } = useFormFields({
		EMAIL: "",
	});

	const [headerClassName, setHeaderClassName] = useState("");

	const handleScroll = (headerClassName) => {
		if (headerClassName !== "topbar" && window.pageYOffset >= 300) {
			setHeaderClassName("is_sticky_home");
		} else {
			setHeaderClassName("");
		}
	};

	useEffect(() => {
		window.onscroll = () => handleScroll();
	}, [headerClassName]);

	return (
		<main>
			<div
				id="topbar"
				className={`flex topbar_home ${headerClassName}`}>
				<div className="col-3"></div>
				<div className="col-6 text-center">
					<img
						src={logo}
						className="logo_home"
						alt="logo"
					/>
				</div>
				<div className="col-3 text-center">
					<a
						href="/menu"
						className="a_menu">
						Menù
					</a>
				</div>
			</div>

			<section className="bottom_bar">
				<a
					href="/menu"
					className="">
					👑 Pizza del mese
				</a>
			</section>

			<section className="container hero flex">
				<img
					src={separator}
					className="separator"
					alt=""
				/>
				<img
					src={hero}
					className="hero_img"
					alt="hero"
				/>
				<img
					src={logo}
					className="logo_hero"
					alt="logo"
				/>
				<h1 className="title_home"></h1>
				<div className="div_hero_btn">
					<a
						href="/menu"
						className="a_menu">
						Menù sala
					</a>
				</div>
				<img
					src={separator}
					className="separator reverse"
					alt=""
				/>
			</section>

			<section
				id="concept"
				className="container">
				<div className="subcategory_home">
					<h2 className="dark">La nostra filosofia</h2>
					<ul className="divider">
						<span></span>
						<span></span>
						<span></span>
					</ul>
				</div>
				<div className="w-100 flex box">
					<p className="dark text">Noi de Il Balconcino Food&Beer crediamo che mangiare non sia solo un azione, ma dei veri momenti per appagare il corpo e la mente. Per questo nei nostri prodotti ci mettiamo tutta la passione che ci contraddistingue, utilizzando materie prime di qualità associate a delle scrupolose lavorazioni, per delle realizzazioni senza eguali!</p>
				</div>
			</section>
			<section className="container news_l">
				<div className="row mailchimp-container">
					<h2 className="dark mx-3">Iscriviti per ricevere in esclusiva promo e novità</h2>
					<form
						className="text-center"
						onSubmit={(event) => {
							event.preventDefault();
							handleSubmit(fields);
							if (message || error) {
								alert(message || error);
								fields.EMAIL = "";
							}
						}}>
						<input
							className="mx-3"
							id="EMAIL"
							type="email"
							value={fields.EMAIL}
							onChange={handleFieldChange}
						/>
						<button className="mt-3">Iscriviti</button>
					</form>
					{loading && "submitting"}
					{error && message}
					{success && message}
				</div>
			</section>
			<section className="container mix flex">
				<img
					src={impasto}
					className="hero_img"
					alt="hero"
				/>
				<div className="subcategory_home">
					<h2 className="white">Il nostro impasto</h2>
					<ul className="divider">
						<span></span>
						<span></span>
						<span></span>
					</ul>
				</div>
				<div className="w-100 flex box">
					<p className="white">Il nostro impasto, realizzato esclusivamente con farina di tipo 2 macinata a pietra, il nostro lievito madre coccolato giorno per giorno, acqua controllata e sale, è dotato di un alto grado di digeribilità e una croccantezza unici nel suo genere.</p>
				</div>

				<img
					src={separator}
					className="separator"
					alt=""
				/>
				<img
					src={separator}
					className="separator reverse"
					alt=""
				/>
			</section>

			<section
				id="concept"
				className="container">
				<div className="card_serv inverso">
					<div className="card_text">
						<div>
							<h2 className="white ">Pizze Gourmet</h2>
							<span className="hr"></span>
							<p className="white">Dalle classiche e intramontabili fino a delle vere e gustose pizze gourmet, tutte le nostre pizze sono realizzate con ingredienti ricercati e di prima scelta</p>
						</div>
					</div>
					<div className="card_img">
						<img
							src={pizza}
							className=""
							alt=""
						/>
					</div>
				</div>
				<div className="card_serv ">
					<div className="card_img">
						<img
							src={tagliere}
							className=""
							alt=""
						/>
					</div>
					<div className="card_text">
						<div>
							<h2 className="white ">I nostri taglieri</h2>
							<span className="hr"></span>
							<p className="white">Ampio spazio alle grandi selezioni di salumi e formaggi pregiati tra cui scegliere</p>
						</div>
					</div>
				</div>

				<div className="card_serv inverso">
					<div className="card_text">
						<div>
							<h2 className="white ">Aperitivi e Fritti Misti</h2>
							<span className="hr"></span>
							<p className="white">Per i più golosi abbiamo un’ampia selezione di fritti, dai classici e tradizionali fino a specialità americane tra formaggi, chili e tante altre golosità…</p>
						</div>
					</div>
					<div className="card_img">
						<img
							src={aperitivo}
							className=""
							alt=""
						/>
					</div>
				</div>
			</section>
			<section className="container mix flex">
				<img
					src={birra}
					className="hero_img"
					alt="hero"
				/>
				<div className="subcategory_home">
					<h2 className="white">L'Arte Di...Gustare La Birra</h2>
					<ul className="divider">
						<span></span>
						<span></span>
						<span></span>
					</ul>
				</div>
				<div className="w-100 flex box">
					<p className="white">
						Per far apprezzare al meglio la birra, bisogna servirla a regola d’arte, con la giusta misura di schiuma, per renderla piacevole sia all’occhio che al palato. Iniziare a spillare con il bicchiere inclinato a 45° perfettamente sgrassato, senza residui e odori. Portarlo lentamente nella posizione verticale man mano che si riempie abbassandolo per provocare più volume e vitalità alla schiuma, che preserverà le caratteristiche volatili ed organolettiche, isolando la birra da contaminazioni esterne. Ne beneficerà anche l’intensità aromatica, il profumo, la fragranza, perchè la birra
						dopo il fermo trascorso all’interno del fusto, ha bisogno di ossigenarsi un po’ per riprendere vita ed esprimere il meglio di sè.
					</p>
				</div>

				<img
					src={separator}
					className="separator"
					alt=""
				/>
				<img
					src={separator}
					className="separator reverse"
					alt=""
				/>
			</section>

			<section className="container contatti">
				<div className="box div_contatti">
					<div>
						<div className="subcategory_home">
							<h2 className="dark">Vieni a trovarci</h2>
							<ul className="divider">
								<span></span>
								<span></span>
								<span></span>
							</ul>
						</div>
						<p>
							<a
								target="_blank"
								href="https://goo.gl/maps/f7yhpAyxAPCeKrDL9"
								rel="noreferrer">
								📌 Via Piave, 13 74020 Avetrana
							</a>
						</p>
						<p>
							<a
								target="_blank"
								href="tel:+393501175128"
								rel="noreferrer">
								📞 +39 350 117 5128
							</a>
						</p>
						<p className="dark">Orari di apertura: 16:30 - 01:00</p>

						<p className="fw-bold dark">Chiusura settimanale: Mercoledì</p>
						<p className="dark text-center pt-1">SUGUICI SUI SOCIAL</p>
						<div className="flex gap15">
							<a href="https://www.facebook.com/ilbalconcinoavetrana">
								{" "}
								<div className="div_icon">
									<img
										src={facebook}
										className=""
										alt=""
									/>
								</div>
							</a>
							<a href="https://www.instagram.com/ilbalconcinofoodbeer/">
								<div className="div_icon">
									<img
										src={instagram}
										className=""
										alt=""
									/>{" "}
								</div>
							</a>
						</div>
					</div>
					<div className="w-100 flex box">
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12162.512546844393!2d17.7276259!3d40.3505945!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1346925aa936640b%3A0xca65ff705b18a0a7!2sIl%20Balconcino%20Food%20%26%20Beer!5e0!3m2!1sit!2sit!4v1702979864060!5m2!1sit!2sit"
							width="100%"
							height="450"
							title="map"
							style={{ border: 0 }}
							allowFullScreen=""
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"></iframe>
					</div>
				</div>
			</section>
			<footer>
				<p>IL BALCONCINO FOOD &amp; BEER - VIA PIAVE 13 74020 AVETRANA (TA)</p>
				<p>POWERED BY</p>
				<a
					href="https://innovalis.it"
					rel="nofollow,noindex">
					<img
						className="logo_footer"
						alt="Innovalis logo"
						src={innovalis}
					/>
				</a>
			</footer>
		</main>
	);
};

export default Home;

// Link dove troverai le props per customizzare il componente
// https://github.com/yifaneye/react-gallery-carousel
